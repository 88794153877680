<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="Outstanding"
        :filter="filter"
        @search="handleSearch"
        @clear="clearFilter"
      >
        <template v-slot:filter-option>
          <b-col>
            <InputText
              v-model="filter.billId"
              textFloat="หมายเลขบิล (POS Ref No.)"
              placeholder="หมายเลขบิล (POS Ref No.)"
              type="text"
              name="billId"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.memberName"
              textFloat="ชื่อสมาชิก (Member Name)"
              placeholder="ชื่อสมาชิก (Member Name)"
              type="text"
              name="member_name"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.telephone"
              textFloat="เบอร์โทรศัพท์ (Telephone)"
              placeholder="เบอร์โทรศัพท์ (Telephone)"
              type="text"
              name="telephone"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputDatePickerFilter
              textFloat="วันที่ใช้ (Start Date)"
              name="expiredDatefrom"
              ref="expiredDatefrom"
              v-model="filter.expiredDatefrom"
              @input="changeFromExpiredDate"
              :v="$v.filter.expiredDatefrom"
              placeholder="DD/MM/YYYY"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputDatePickerFilter
              textFloat="วันสิ้นสุดการใช้ (End Date)"
              name="expiredDateto"
              ref="expiredDateto"
              v-model="filter.expiredDateto"
              @input="onChangeToExpiredDate"
              :v="$v.filter.expiredDateto"
              placeholder="DD/MM/YYYY"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputSelect
              title="ราคา (Price)"
              isRequired
              :options="spendingList"
              v-model="filter.spendId"
              @onDataChange="val => (filter.spendId = val)"
              textField="name"
              valueField="id"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.quantity"
              textFloat="จำนวนคงเหลือน้อยกว่า (Remaining Amount)"
              placeholder="จำนวนคงเหลือน้อยกว่า (Remaining Amount)"
              type="text"
              name="quantity"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputSelect
              title="กลับมาซื้อภายใน X เดือน (Return Month)"
              isRequired
              :options="monthList"
              v-model="filter.returnMonth"
              @onDataChange="val => (filter.returnMonth = val)"
              textField="name"
              valueField="id"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputSelect
              title="ไม่กลับมาซื้อภายใน X เดือน (No Return Month)"
              isRequired
              :options="monthNoList"
              v-model="filter.noReturnMonth"
              @onDataChange="val => (filter.noReturnMonth = val)"
              textField="name"
              valueField="id"
              class="mb-2"
            />
          </b-col>
        </template>
      </HeaderPanel>
      <div class="bg-white border-red mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
            >
              <template v-slot:cell(email)="data">
                <div>{{ data.item.email || '-' }}</div>
              </template>
              <template v-slot:cell(packagePrice)="data">
                <div>{{ data.item.packagePrice | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(price)="data">
                <div>{{ data.item.price | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(outstandingAmount)="data">
                <div>{{ data.item.outstandingAmount | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(burnIncome)="data">
                <div>{{ data.item.burnIncome | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(treatmentValue)="data">
                <div>{{ data.item.treatmentValue | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(originalBurnIncome)="data">
                <div>
                  {{ data.item.originalBurnIncome | numeral('0,0.00') }}
                </div>
              </template>
              <template v-slot:cell(spaBalance)="data">
                <div>{{ data.item.spaBalance | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(lastPurchaseTime)="data">
                <div>
                  {{
                    $moment(data.item.lastPurchaseTime, 'MM/DD/YYYY HH:mm:ss')
                      .add(543, 'years')
                      .format($formatDateNewFull)
                  }}
                </div>
              </template>
              <template v-slot:cell(lastUseTime)="data">
                <div v-if="data.item.lastUseTime">
                  {{
                    $moment(data.item.lastUseTime, 'MM/DD/YYYY HH:mm:ss')
                      .add(543, 'years')
                      .format($formatDateNewFull)
                  }}
                </div>
                <div v-else>-</div>
              </template>
              <template v-slot:cell(expiredDate)="data">
                <div>
                  {{
                    $moment(data.item.expiredDate, 'MM/DD/YYYY HH:mm:ss')
                      .add(543, 'years')
                      .format($formatDateNewFull)
                  }}
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
    <ModalNote ref="modalNote" :branchId="branchId" />
  </div>
</template>

<script>
import OtherLoading from '@/components/loading/OtherLoading';
import HeaderPanel from '@/components/HeaderPanel';
import Pagination from '@/components/Pagination';
import InputSelect from '@/components/inputs/InputSelect';
import InputText from '@/components/inputs/InputText';
import ModalNote from '@/components/report/refSaleMember/ModalNote';
import InputDatePickerFilter from '@/components/inputs/InputDatePickerFilter';
import { mapGetters } from 'vuex';
import { minValue, maxValue } from 'vuelidate/lib/validators';

const minDate = (value, fromDate) => {
  if (!value) return true;
  let minDate = fromDate;
  return new Date(value) >= new Date(minDate);
};
const maxDate = (value, toDate = null) => {
  let maxDate = toDate || new Date();
  return new Date(maxDate) >= new Date(value);
};

export default {
  components: {
    OtherLoading,
    Pagination,
    HeaderPanel,
    InputSelect,
    InputText,
    ModalNote,
    InputDatePickerFilter
  },
  name: 'ReportOutstanding',
  data() {
    return {
      fields: [
        {
          key: 'memberId',
          label: 'รหัสสมาชิก'
        },
        {
          key: 'firstNameTh',
          label: 'ชื่อ'
        },
        {
          key: 'lastNameTh',
          label: 'นามสกุล'
        },
        {
          key: 'telephone',
          label: 'เบอร์โทร'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'invoiceNo',
          label: 'Invoice No.'
        },
        {
          key: 'spaType',
          label: 'ประเภท'
        },
        {
          key: 'name',
          label: 'แพ็คเกจ/ทรีทเมนต์'
        },
        {
          key: 'single',
          label: 'Single Name'
        },
        {
          key: 'lastPurchaseTime',
          label: 'วันที่ซื้อ'
        },
        {
          key: 'packagePrice',
          label: 'ราคาขาย / แพ็คเกจ'
        },
        {
          key: 'price',
          label: 'ราคา'
        },
        {
          key: 'outstandingAmount',
          label: 'Outstanding Amount'
        },
        {
          key: 'burnIncome',
          label: 'Burn Income'
        },
        {
          key: 'totalQty',
          label: 'จำนวนทั้งหมด'
        },
        {
          key: 'outstandingQty',
          label: 'จำนวนคงเหลือ'
        },
        {
          key: 'treatmentValue',
          label: 'Treatment Value'
        },
        {
          key: 'originalBurnIncome',
          label: 'Original Burn Income'
        },
        {
          key: 'spaBalance',
          label: 'Spa Balance'
        },
        {
          key: 'lastUseTime',
          label: 'วันที่ใช้ล่าสุด'
        },
        {
          key: 'expiredDate',
          label: 'วันหมดอายุ'
        }
      ],
      items: [],
      isBusy: false,
      isLoadingData: false,
      rows: 0,
      filter: {
        page: 1,
        take : 10,
        expiredDatefrom: null,
        expiredDateto: null,
        billId: '',
        memberName: '',
        telephone: '',
        spendId: 0,
        returnMonth: 0,
        noReturnMonth: 0
      },
      pageOptions: [
        { value: 5, text: '5 / page' },
        { value: 10, text: '10 / page' },
        { value: 30, text: '30 / page' },
        { value: 50, text: '50 / page' },
        { value: 100, text: '100 / page' }
      ],
      selected: 0,
      isLoading: true,
      branchList: [],
      spendingList: [],
      monthNoList: [],
      monthList: [],
      memberTypeOptions: false,
      today: null
    };
  },
  validations: {
    filter: {
      expiredDatefrom: {
        maxValue(val, { expiredDateto }) {
          return maxDate(val, expiredDateto);
        }
      },
      expiredDateto: {
        minValue(val, { expiredDatefrom }) {
          return minDate(val, expiredDatefrom);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      branchId: 'getBranchId'
    })
  },
  created: async function () {
    this.today = this.$moment().format('YYYY-MM-DDT00:00:00');
    this.getBranchList();
    this.getSpendingRangeList();
    this.getMonthList();
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.$v.filter.$touch();
      if (this.$v.filter.$error) return;

      this.isBusy = true;
      let payload = { ...this.filter };
      payload.skip = (payload.page - 1) * payload.take;
      payload.member_name = payload.memberName;
      payload.spend_id = payload.spendId;
      payload.return_month = payload.returnMonth;
      payload.no_return_month = payload.noReturnMonth;

      this.isLoadingData = true;
      await this.axios
        .post(`${this.$baseUrl}/report/get_outstanding`, payload)
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.items = data.detail.detail;
            this.rows = data.detail.total_count;
            this.isLoadingData = false;
          }
        });

      this.isBusy = false;
    },
    getBranchList: async function () {
      let list = [{ id: 0, name: 'ทั้งหมด' }];

      await this.axios.get(`${this.$baseUrl}/branch/list`).then(data => {
        if (data.result == 1) {
          data.detail.map(el => {
            el.id, (el.name = `${el.plant_id} ${el.name}`);
          });
          this.branchList = list.concat(data.detail);
        }
      });
    },
    getMonthList: async function () {
      let list = [{ id: 0, name: 'ทั้งหมด' }];
      await this.axios
        .get(`${this.$baseUrl}/dropdown/get_month_list`)
        .then(data => {
          if (data.result == 1) {
            let filtered = data.detail.filter(el => el.id != '0');
            data.detail.map(el => {
              el.id, (el.name = el.id);
            });
            this.monthList = list.concat(filtered);
            this.monthNoList = this.monthList;
          }
        });
    },
    getSpendingRangeList: async function () {
      await this.axios
        .get(`${this.$baseUrl}/dropdown/get_spending_range`)
        .then(data => {
          if (data.result == 1) {
            this.spendingList = data.detail;
          }
        });
    },
    handleSearch(value) {
      this.pagination(1);
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    clearFilter() {
      this.$v.filter.$reset();
      this.filter = {
        page: 1,
        take : 10,
        expiredDatefrom: null,
        expiredDateto: null,
        billId: '',
        memberName: '',
        telephone: '',
        spendId: 0,
        returnMonth: 0,
        noReturnMonth: 0
      };
      this.getList();
    },
    changeFromExpiredDate(val) {
      this.filter.expiredDatefrom = val;
    },
    onChangeToExpiredDate(val) {
      this.filter.expiredDateto = val;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .table-main {
  table {
    min-width: max-content;
  }
}
.time {
  color: #afafaf;
}
.line-clamp2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.badge {
  font-size: 85%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  max-width: max-content;
  font-weight: 100;
}
.badge-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.icon {
  font-size: 24px;
  color: #000 !important;
}
</style>
